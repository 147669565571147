import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/whatsapp.png";
import Navbar from "../components/Navbar";
import { FiArrowRight } from "react-icons/fi";
import About from "./About";
import Work from "./Work";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">NCK Infotech Pvt Ltd</h1>
          <p className="primary-text">
            Send Bulk Whatsapp messages, images, files etc using our software.
            You can even send customized Dynamic messages by importing data from
            an excel sheet.
          </p>
          <button className="secondary-button">
            See Our Offer Details <FiArrowRight />{" "}
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
      <About />
      <Work />
      <Footer />
    </div>
  );
};

export default Home;
