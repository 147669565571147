import React from "react";
import Navbar from "../components/Navbar";
import contact from "../Assets/contact.jpg";
import Footer from "../components/Footer";
import ReactPlayer from "react-player";

const Google = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="content">
          <div className="image-box">
            <ReactPlayer
              controls={true}
              url="https://www.youtube.com/watch?v=5KmGulKH1Ms&t=4s"
            />
            <h2 style={{ paddingTop: "12px" }}>Price: Rs 6500</h2>
          </div>
          <form style={{ paddingLeft: "100px" }}>
            <div className="topic">NCK Infotech Pvt Ltd</div>
            <div
              className="topic"
              style={{ paddingTop: "25px", color: "#0e4c70" }}
            >
              <label>
                You can send bulk whatsapp messages without saving the contacts
                in your contact list. just upload the contacts using a file or
                just paste them in the software and start sending the messages.
                you can even attach images, videos, files etc. the software also
                allows you to send dynamic messages with names and other
                variables.
              </label>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Google;
