import React from "react";
import PickMeals from "../Assets/pick-meals-image.png";
import database from "../Assets/database.png";
import whatsapp from "../Assets/whatsapp.png";

const Work = () => {
  const workInfoData = [
    {
      image: database,
      title: "Database Software",
      text: "This is a software that can extract data from google maps website without API. The advantage is that is can extract without API and can extract all the contacts without any limitations",
    },
    {
      image: whatsapp,
      title: "NCK Infotech Pvt Ltd",
      text: "Send Bulk Whatsapp messages, images, files etc using our software. You can even send customized Dynamic messages by importing data from an excel sheet.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <h1 className="primary-heading">Popular Products</h1>
        <p className="primary-text">
          We offer various kind of softwares especially to generate leads for
          the business. 100% genuine leads will make sure that your business
          grows faster. we usually use bulk sms or bulk email marketing services
          to reach our target audience. to send these sms or emails we need
          phone numbers and email addresses. its very crucial to have correct
          phone numbers and email addresses, else you will end up spending money
          on a marketing campaign which is not fruitful, because you are not
          reaching your targeted audience. These lead generation softwares will
          give you just what you need.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
