import React from "react";
import Navbar from "../components/Navbar";
import contact from "../Assets/contact.jpg";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="content">
          <div className="image-box">
            <img src={contact} alt=""></img>
          </div>
          <form action="#">
            <div className="topic">Send us a message</div>
            <div className="input-box">
              <input type="text" required></input>
              <label>Enter your name</label>
            </div>
            <div className="input-box">
              <input type="text" required></input>
              <label>Enter your email</label>
            </div>
            <div className="input-box">
              <input type="number" required></input>
              <label>Enter your phone</label>
            </div>
            <div className="input-box">
              <input type="text" required></input>
              <label>Enter your message</label>
            </div>
            <div className="input-box">
              <input type="submit" value="Send Message"></input>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
