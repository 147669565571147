import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Softwares from "./pages/Softwares";
import Contact from "./pages/Contact";
import wp from "./Assets/wp.gif";
import call from "./Assets/call.gif";
import Google from "./pages/database";
import Bulkmsg from "./pages/bulkmsg";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/softwares" element={<Softwares />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/database" element={<Bulkmsg />} />
          <Route path="/bulkmsg" element={<Google />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
      <div>
        <div
          className="fixed-bottom right-100 p-3"
          style={{ zIndex: "6", left: "initial", background: "" }}
        >
          <a
            href="https://wa.me/918208720421?text=Hello, How Can I Help You?"
            target="_blank"
          >
            <img src={wp} alt="" />
          </a>
        </div>
        <div
          className="fixed-bottom right-100 p-3"
          style={{ zIndex: "6", left: "initial", marginBottom: "74px" }}
        >
          <a href="tel:918208720421">
            <img src={call} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
