import React from "react";
import PickMeals from "../Assets/pick-meals-image.png";
import database from "../Assets/database.png";
import whatsapp from "../Assets/whatsapp.png";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { BsTwitter, BsYoutube } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { FaFacebookF } from "react-icons/fa";
import Logo from "../Assets/logo.png";

const Softwares = () => {
  const workInfoData = [
    {
      image: database,
      route: "/database",
      title: "Database Software",
      text: "This is a software that can extract data from google maps website without API. The advantage is that is can extract without API and can extract all the contacts without any limitations",
    },
    {
      image: whatsapp,
      route: "/bulkmsg",
      title: "NCK Infotech Pvt Ltd",
      text: "Send Bulk Whatsapp messages, images, files etc using our software. You can even send customized Dynamic messages by importing data from an excel sheet.",
    },
  ];
  return (
    <div>
      <Navbar />
      <div className="work-section-wrapper" style={{ marginTop: "0" }}>
        <div className="work-section-top">
          <h1 className="primary-heading">Popular Products</h1>
        </div>
        <div className="work-section-bottom" style={{ height: "250px" }}>
          {workInfoData.map((data) => (
            <div
              className="work-section-info"
              key={data.title}
              style={{ width: "500px" }}
            >
              <div className="info-boxes-img-container">
                <img src={data.image} alt="" />
              </div>
              <Link to={data.route}>
                <h2>{data.title}</h2>
              </Link>
              <p>{data.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="footer-wrapper" style={{ marginTop: "35rem" }}>
        <div className="footer-section-one">
          <div className="footer-logo-container">
            <img src={Logo} alt="" />
          </div>
          <div className="footer-icons">
            <BsTwitter />
            <SiLinkedin />
            <BsYoutube />
            <FaFacebookF />
          </div>
        </div>
        <div className="footer-section-two">
          <div className="footer-section-columns">
            <span>Qualtiy</span>
            <span>Help</span>
            <span>Share</span>
            <span>Carrers</span>
            <span>Testimonials</span>
            <span>Work</span>
          </div>
          <div className="footer-section-columns">
            <span>244-5333-7783</span>
            <span>hello@food.com</span>
            <span>press@food.com</span>
            <span>contact@food.com</span>
          </div>
          <div className="footer-section-columns">
            <span>Terms & Conditions</span>
            <span>Privacy Policy</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Softwares;
