import React from "react";
import Navbar from "../components/Navbar";
import contact from "../Assets/contact.jpg";
import Footer from "../components/Footer";
import ReactPlayer from "react-player";

const Bulkmsg = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="content">
          <div className="image-box">
            <ReactPlayer
              controls={true}
              url="https://www.youtube.com/watch?v=i42dxEkXPxk"
            />
            <h2 style={{ paddingTop: "24px" }}>Price: Rs 8000</h2>
          </div>
          <form style={{ paddingLeft: "100px" }}>
            <div className="topic">Database Software</div>
            <div
              className="topic"
              style={{ paddingTop: "25px", color: "#0e4c70" }}
            >
              <label>
                This software can extract data from google maps website without
                API. The advantage is that is can extract without API and can
                extract all the contacts without any limitations. The keyword
                also supports multiple keywords and locations. You dont need to
                spend your time sitting in front of the computer. just copy and
                paste the list of keywords and locations and the software will
                do the work. The software can even extract the email addresses
                by visiting the websites and checking for emails.
              </label>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Bulkmsg;
